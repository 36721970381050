<template>
  <h1 class="title">智言AI</h1>
  <div class="home-buttons-container">
    <button class="home-button primary" @click="navigateToWithToken('https://chat.chatgptten.com/gpt3', 'ChatGPT3.5')">
      ChatGPT-3.5（普通版）
    </button>
    <button class="home-button primary" @click="navigateToWithToken('https://chat.chatgptten.com/skip', 'AI绘画(BETA)')">
      AI 绘画（普通版）
    </button>
    <!--    <button class="home-button success" @click="navigateToWithToken('http://20.21.102.50:7084/llm4/zh', 'ChatGPT4')">-->
    <!--      ChatGPT-4.0-->
    <!--    </button>-->
    <button class="home-button success" @click="navigateToWithToken('https://chat.chatgptten.com/plus4', 'ChatGPT4-beta')">
      ChatGPT-4.0（年卡高级版）
    </button>
    <!--    <button class="home-button success" @click="navigateToWithToken('https://gateway-mj.zeabur.app/skip', 'AI绘画')">-->
    <!--      AI 绘画-->
    <!--    </button>-->

    <button class="home-button success" @click="navigateToWithToken('https://chat.chatgptten.com/adv/', 'AI绘画(BETA)')">
      AI 绘画（年卡高级版）
    </button>
    <button class="home-button success" @click="navigateToWithToken('https://chat.chatgptten.com/pro/painting/', 'AI绘画')">
      AI 绘画（年卡专业版）
    </button>
    <button class="home-button success" @click="navigateToWithToken('https://chat.chatgptten.com/summary', 'AI摘要')">
      AI 一键总结（BETA）
    </button>
    <button v-if="getToken() !== null" class="home-button danger" @click="logout">
      登出
    </button>
  </div>
</template>

<script>
export default {
  name: 'NXHomePage',
  methods: {
    getToken() {
      const token = localStorage.getItem('token');
      const tokenExpiryDate = localStorage.getItem('tokenExpiryDate');
      const currentDate = new Date();

      if (tokenExpiryDate && new Date(tokenExpiryDate) < currentDate) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiryDate');
        return null;
      }

      return token;
    },
    async navigateToWithToken(url, title) {
      const token = this.getToken();

      if (!token) {
        this.$router.push('/login');
        return;
      }

      try {
        // 添加时间戳来避免浏览器缓存
        const timestamp = new Date().getTime();
        const fullUrl = `${url}?token=${token}&t=${timestamp}`;

        window.open(fullUrl, '_blank');
        console.log(`Navigating to: ${title}`);
        console.log('URL:', fullUrl);
      } catch (error) {
        console.error('Error during the request:', error.message);
      }
    },
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiryDate');
      this.$router.push('/login');
    }
  },
};
</script>

<style scoped>
.home-buttons-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Roboto, Helvetica, Arial, sans-serif; /* 设置通用字体 */
}

.home-button {
  background: transparent;
  cursor: pointer;
  border: none;
  border-radius: 28px; /* 修改圆角大小为 28px */
  font-size: 18px;
  padding: 16px 24px;
  margin-bottom: 20px;
  color: #fff;
  width: 300px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* 设置每个按钮的颜色 */
.primary {
  background-color: #1976d2;
}

.success {
  background-color: #388e3c;
}

.info {
  background-color: #039be5;
}

.danger {
  background-color: #d32f2f;
}


/* 设置按钮悬停和激活效果 */
.home-button:hover,
.home-button:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
}

.home-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.title {
  text-align: center;
  color: #1976d2; /* 可以调整颜色以匹配页面的主题色 */
  font-size: 36px; /* 根据页面的大小调整标题的大小 */
  font-weight: bold; /* 使标题字体加粗 */
  margin-bottom: 30px; /* 在标题下方增加一些空间 */
  font-family: Roboto, Helvetica, Arial, sans-serif; /* 与其他文本保持一致的字体 */
}
</style>