<template>
  <div class="flex flex-col h-screen bg-black text-white" @click="handleOutsideClick">
    <!-- 顶部导航栏 -->
    <nav class="bg-black shadow-md">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0 flex items-center">
              <!-- Netflix风格的logo -->
              <img class="h-8 w-auto" src="https://gw.chatgptten.com/icons/icon-192x192.png" alt="智言AI Logo">
            </div>
            <!-- 桌面导航 -->
            <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <a v-for="button in visibleButtons" :key="button.text"
                 :href="button.href"
                 class="text-gray-300 hover:text-white inline-flex items-center px-1 pt-1 text-sm font-medium"
                 :class="{ 'border-b-2 border-red-500 text-white': button.isActive }"
                 @click.prevent="handleNavClick(button)">
                {{ button.text }}
              </a>
            </div>
          </div>
          <!-- 用户菜单 -->
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <div class="ml-3 relative">
              <div>
                <button type="button" class="bg-gray-800 rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
                </button>
              </div>
            </div>
          </div>
          <!-- 添加汉堡菜单按钮 -->
          <div class="sm:hidden flex items-center">
            <button @click="toggleMobileMenu" class="text-gray-300 hover:text-white">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- 移动端导航菜单 -->
      <div v-show="isMobileMenuOpen" class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <a v-for="button in visibleButtons" :key="button.text"
             :href="button.href"
             class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
             @click.prevent="handleMobileMenuItemClick(button)">
            {{ button.text }}
          </a>
        </div>
      </div>
    </nav>

    <!-- 主要内容区 -->
    <main class="flex-grow overflow-hidden">
      <iframe
          :src="currentIframeSrc"
          frameborder="0"
          class="w-full h-full"
      ></iframe>
    </main>

    <!-- 客服弹窗 -->
    <div v-if="showQcodeModal" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75">
      <div class="bg-gray-900 rounded-lg shadow-lg p-6">
        <img :src="qcodeSrc" alt="客服二维码" class="mx-auto w-48">
        <button class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4" @click="showQcodeModal = false">
          关闭
        </button>
      </div>
    </div>

    <!-- 页脚 -->
    <footer class="bg-black">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div class="flex flex-wrap justify-center space-x-6">
          <a href="#" @click.prevent="goToAbout" class="text-gray-400 hover:text-white">关于我们</a>
          <a href="#" @click.prevent="goToNews" class="text-gray-400 hover:text-white">最新资讯</a>
          <a href="#" @click.prevent="goToCareers" class="text-gray-400 hover:text-white">工作机会</a>
          <a href="#" @click.prevent="goToPrivacyPolicy" class="text-gray-400 hover:text-white">开发文档</a>
          <a href="#" @click.prevent="openPlatform" class="text-gray-400 hover:text-white">开放平台</a>
          <a href="#" @click.prevent="goMonitor" class="text-gray-400 hover:text-white">服务监控</a>
          <span class="text-gray-400">© 2024 智言AI. 版权所有.</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'HomePage',
  setup() {
    const router = useRouter();
    const route = useRoute();

    const currentIframeSrc = ref('https://gw.chatgptten.com/');
    const showQcodeModal = ref(false);
    const qcodeSrc = ref('https://chat.chatgptten.com/images/weixin.png');
    const hasTag = ref(false);
    const invitationCode = ref('');
    const userLoggedIn = ref(false);
    const isMobileMenuOpen = ref(false);
    const pValue = ref(null);

    const allButtons = ref([
      { text: 'Luma 视频生成', action: () => updateIframe('https://chat.chatgptten.com/video-generator2'), isActive: false },
      { text: 'Runway 视频生成', action: () => updateIframe('https://mall.chatgptten.com/video-generator'), isActive: false },
      { text: 'KLING 视频生成', action: () => updateIframe('https://mall.chatgptten.com/imagine-creator'), isActive: false },
      // { text: 'AI 绘画', action: () => updateIframe('https://chat.chatgptten.com/imagine-creator'), isActive: false },
      { text: 'AI 对话', action: () => updateIframe('https://gw.chatgptten.com/'), isActive: true },
      { text: 'AI 音乐绘画', action: () => updateIframe('https://study.chatgptten.com/'), isActive: false },
      { text: '图库壁纸', action: () => updateIframe('/image-gallery'), isActive: false },
      { text: '套餐充值', action: () => updateIframe('https://mall.chatgptten.com/'), isActive: false },
      { text: '联系客服', action: showQcode, isActive: false },
    ]);

    const visibleButtons = computed(() => {
      if (pValue.value === '1' || localStorage.getItem('pValue') === '1') {
        return allButtons.value.filter(button =>
            button.text !== '联系客服' && button.text !== '套餐充值'
        );
      }
      return allButtons.value;
    });

    onMounted(() => {
      initializeComponent();
      handleQueryParams(); // 在组件加载完成后立即处理查询参数
    });

    function initializeComponent() {
      handlePValue();
      handleInvitationCode();
      // handleQueryParams(); // 移到 onMounted 中
      fetchAndUpdateLocalStorage();
    }

    function handlePValue() {
      const urlParams = new URLSearchParams(window.location.search);
      const p = urlParams.get('p');
      if (p) {
        pValue.value = p;
        localStorage.setItem('pValue', p);
      } else {
        pValue.value = localStorage.getItem('pValue');
      }
    }

    function handleInvitationCode() {
      const removeInvitationCode = route.query.removeInvitationCode;
      if (removeInvitationCode === 'delete') {
        localStorage.removeItem('invitationCode');
        console.log('invitationCode removed. Skipping further execution.');
        return;
      }

      const storedInvitationCode = localStorage.getItem('invitationCode');
      if (storedInvitationCode) {
        invitationCode.value = storedInvitationCode;
      } else {
        invitationCode.value = route.query.invitationCode;
        if (invitationCode.value) {
          localStorage.setItem('invitationCode', invitationCode.value);
        }
      }
      console.log('The invitation code is:', invitationCode.value);
    }

    function handleQueryParams() {
      const { token, uid, expiration, tag, s } = route.query;

      if (token) localStorage.setItem("token", token);
      if (uid) localStorage.setItem("uid", uid);
      if (expiration) localStorage.setItem("expiration", expiration);
      if (tag) localStorage.setItem("tag", tag);

      console.log('Token:', token, 'UID:', uid, 'Expiration:', expiration, 'Tag:', tag);

      if (s) {
        // 如果 s 参数存在，则根据 s 的值设置对应的按钮为激活状态
        const targetIndex = allButtons.value.findIndex(button => button.text === '套餐充值');
        if (targetIndex !== -1) {
          setActiveButton(targetIndex);
          allButtons.value[targetIndex].action(); // 执行对应按钮的动作，即跳转到套餐充值页面
        }
      }
    }

    function fetchAndUpdateLocalStorage() {
      updateButtonsBasedOnAuth();

      const uid = localStorage.getItem('uid');

      if (!uid) {
        console.log('UID is not available. Exiting the method.');
        return;
      }

      const url = `https://chat.chatgptten.com/u/global_users/user/uid/${uid}`;

      fetch(url)
          .then(response => response.json())
          .then(data => {
            userLoggedIn.value = data.success;

            if (data && data.data) {
              const { expirationTime, tag } = data.data;
              const expirationTimestamp = new Date(expirationTime).getTime();
              console.log('Fetched expirationTime:', expirationTime);
              console.log('Fetched expirationTimestamp:', expirationTimestamp);
              console.log('Fetched tag:', tag);

              if (expirationTime) localStorage.setItem("expiration", expirationTimestamp.toString());
              if (tag) localStorage.setItem("tag", tag);
            }

            updateButtonsBasedOnAuth();
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            updateButtonsBasedOnAuth();
          });
    }

    function updateButtonsBasedOnAuth() {
      const tag = localStorage.getItem('tag');
      hasTag.value = !!tag;
      console.log('Current tag value:', tag);

      // 获取URL参数
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      allButtons.value = allButtons.value.filter(button => button.text !== '我的资料' && button.text !== '登录/注册');

      if (hasTag.value || token) { // 如果有tag或者token，则显示"我的资料"
        allButtons.value.push({ text: '我的资料', action: setting, isActive: false });
      } else {
        allButtons.value.push({ text: '登录/注册', action: login, isActive: false });
      }

      console.log('Buttons updated:', allButtons.value);
    }

    function updateIframe(newSrc) {
      currentIframeSrc.value = newSrc;
    }

    function showQcode() {
      showQcodeModal.value = true;
    }

    function setting() {
      router.push('/setting');
    }

    function login() {
      // router.push('/login');
      window.open('https://chat.chatgptten.com/sso/auth?mode=simple&redirect=https://chat.chatgptten.com/', '_blank');

    }

    function goToAbout() {
      router.push('/about');
    }

    function goToNews() {
      window.open('https://news.chatgptten.com/', '_blank');
    }

    function goToCareers() {
      router.push('/careers');
    }

    function goToPrivacyPolicy() {
      // router.push('/privacy-policy');
      window.open('https://apifox.chatgptten.com/', '_blank');
    }

    function goMonitor() {
      // router.push('/privacy-policy');
      window.open('https://monitor.chatgptten.com/status/llm', '_blank');
    }

    function toggleMobileMenu() {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    }

    function handleOutsideClick(event) {
      if (isMobileMenuOpen.value && !event.target.closest('.sm\\:hidden')) {
        isMobileMenuOpen.value = false;
      }
    }

    function handleMobileMenuItemClick(button) {
      button.action();
      isMobileMenuOpen.value = false;
    }

    function handleNavClick(button) {
      allButtons.value.forEach(btn => btn.isActive = false);
      button.isActive = true;
      button.action();
    }

    watch(allButtons, (newButtons) => {
      console.log('Buttons changed:', newButtons);
    }, { deep: true });

    const setActiveButton = (index) => {
      allButtons.value.forEach((button, i) => {
        button.isActive = i === index;
      });
    };

    const openPlatform = () => {
      // 检查用户是否登录
      if (localStorage.getItem('token')) {
        // 如果已登录，在 iframe 中加载目标链接
        updateIframe('https://mall.chatgptten.com/admin');
      } else {
        // 如果未登录，弹出提示
        alert('您尚未登录，请先登录');
        // 可以选择跳转到登录页面
        // router.push('/login');
      }
    };

    return {
      currentIframeSrc,
      showQcodeModal,
      qcodeSrc,
      visibleButtons,
      isMobileMenuOpen,
      updateIframe,
      showQcode,
      goToAbout,
      goToNews,
      goToCareers,
      goToPrivacyPolicy,
      goMonitor,
      setActiveButton,
      toggleMobileMenu,
      handleOutsideClick,
      handleMobileMenuItemClick,
      handleNavClick,
      openPlatform,
    };
  },
};
</script>

<style scoped>
/* Netflix风格的自定义样式 */
body {
  background-color: #141414;
  color: #ffffff;
}

.bg-red-600 {
  background-color: #e50914;
}

.hover\:bg-red-700:hover {
  background-color: #b20710;
}

.focus\:ring-red-500:focus {
  --tw-ring-color: #e50914;
}

/* 可以根据需要添加更多自定义样式 */
</style>